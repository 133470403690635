<template>
  <div
    :id="`send-message-node-inner-${upline.agent_id}`"
    :class="`d-none msg-node msg-node-${upline.agent_id}`"
  >
    <div
      v-for="downlineList in downline"
      :key="downlineList.agent_id"
      :class="`node-branch node-branch-${downlineList.agent_id}`"
    >
      <div class="branch">
        <div
          class="line-link"
          :style="firstSubNode ? 'margin-left: 0.1rem' : ''"
        >
          <div :class="firstSubNode ? 'entry sub' : 'entry'">
            <img
              src="@/assets/images/right-arrow-icon.svg"
              class="right-arrow-icon"
            />
            <rep-tree-card
              :rep="downlineList"
              className="spilt-down"
              @onCheckAllDownline="onCheckAllDownlineHandler"
              @onRemoveDownlines="removeRelatedDownlines"
              :parentUplineId="upline.agent_id"
            />
            <template v-if="downlineList.downline">
              <rep-tree-node
                :downline="downlineList.downline"
                :upline="downlineList"
                @onUpdateSelectedReps="newSelectedReps"
              />
            </template>
            <!-- recursive call to downline_node_1.downline -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RepTreeCard from "./RepTreeCard.vue";
export default {
  components: { RepTreeCard },
  name: "RepTreeNode",
  props: {
    downline: {
      required: true,
      type: Array,
      default: null,
    },
    upline: {
      required: true,
      type: Object,
    },
    firstSubNode: {
      required: false,
      type: Boolean,
      default: false,
    },
    allSelectedReps: {
      required: false,
      type: Array,
      default: null,
    },
  },
  data: () => ({
    nodeSelectedReps: [],
  }),
  methods: {
    newSelectedReps(newSelectedReps) {
      let storedReps = this.$store.getters.sendRepMessageSelectedReps;
      this.$store.commit(
        "setSendRepMessageSelectedReps",
        new Set([...storedReps, ...newSelectedReps])
      );
    },
    removeItems(array, itemToRemove) {
      return array.filter((v) => {
        return !itemToRemove.includes(v);
      });
    },

    checkNode(array, downlines = []) {
      if (array.length > 0) {
        array.forEach((element) => {
          let div = document.getElementById(
            `send-message-node-inner-${element}`
          );
          if (array.includes(element)) {
            if (div) {
              div.classList.remove("d-none");
              document.getElementById(`toggle-${element}`).innerHTML = "-";
            }
            document.getElementById(`upline-${element}`).checked = true;
          } else {
            if (div) {
              div.classList.add("d-none");
              document.getElementById(`toggle-${element}`).innerHTML = "+";
            }
            document.getElementById(`upline-${element}`).checked = false;
          }
        });
      } else {
        if (downlines) {
          downlines.forEach((element) => {
            let div = document.getElementById(
              `send-message-node-inner-${element}`
            );
            if (div) {
              div.classList.add("d-none");
              document.getElementById(`toggle-${element}`).innerHTML = "+";
            }
            // keep tree expanded if all downline checkbox is checked and its upline is unchecked
            const uplineCheckbox = document.getElementById(`upline-${element}`);
            const allDownlineCheckbox = document.getElementById(
              `allDownline-${element}`
            );
            if (
              uplineCheckbox &&
              allDownlineCheckbox &&
              allDownlineCheckbox.checked &&
              !uplineCheckbox.checked
            ) {
              div.classList.remove("d-none");
              document.getElementById(`toggle-${element}`).innerHTML = "-";
            }
            document.getElementById(`upline-${element}`).checked = false;
          });
        }
      }
      /**
       * storing 1st node
       */
      this.newSelectedReps(array);
      this.$nextTick(() => {
        this.$emit("onUpdateSelectedReps", new Set([...array]));
      });
    },

    removeRelatedDownlines(downlines) {
      // console.log(
      //   "node remove",
      //   this.$store.getters.sendRepMessageSelectedReps,
      //   downlines
      // );
      let allSelectedReps = this.removeItems(
        this.$store.getters.sendRepMessageSelectedReps,
        downlines
      );
      let result = new Set([...allSelectedReps]);
      this.$store.commit("setSendRepMessageSelectedReps", result);
      this.checkNode(result, downlines);
    },
    onCheckAllDownlineHandler(selectedReps) {
      let storedReps = this.$store.getters.sendRepMessageSelectedReps;
      let allSelectedReps = [...new Set([...selectedReps, ...storedReps])];

      this.checkNode(allSelectedReps);
    },
  },
};
</script>
