<template>
  <button
    class="toggle"
    title="Expand/Collapse"
    @click="toggleList(uplineId, $event)"
    :id="`toggle-${uplineId}`"
  >
    +
  </button>
</template>
<script>
export default {
  name: "RepTreeToggle",
  props: {
    uplineId: {
      required: true,
      type: Number,
      default: null,
    },
  },
  methods: {
    toggleList(id, event) {
      event.target.innerHTML = event.target.innerHTML == "-" ? "+" : "-";
      let div = document.getElementById(`send-message-node-inner-${id}`);
      if (div) {
        div.classList.toggle("d-none");
      }
    },
  },
};
</script>