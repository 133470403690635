var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "toggle",
    attrs: {
      "title": "Expand/Collapse",
      "id": "toggle-".concat(_vm.uplineId)
    },
    on: {
      "click": function click($event) {
        return _vm.toggleList(_vm.uplineId, $event);
      }
    }
  }, [_vm._v(" + ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }