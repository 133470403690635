var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: "spilt ".concat(_vm.className, " card-").concat(_vm.rep.agent_id)
  }, [_c('div', {
    staticClass: "displayLine"
  }, [_vm.rep.downline && _vm.rep.downline.length > 0 ? [_c('input', {
    staticClass: "mr-3 custom-check",
    attrs: {
      "type": "checkbox",
      "id": "allDownline-".concat(_vm.rep.agent_id),
      "title": "Select All Downline"
    },
    on: {
      "change": function change($event) {
        return _vm.checkAllDownline(_vm.rep.downline, _vm.rep.agent_id);
      }
    }
  })] : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedReps,
      expression: "selectedReps"
    }],
    staticClass: "mr-3 custom-check",
    class: "upline-".concat(_vm.parentUplineId, " rep-checkbox"),
    attrs: {
      "type": "checkbox",
      "id": "upline-".concat(_vm.rep.agent_id),
      "title": "Select Rep"
    },
    domProps: {
      "value": _vm.rep.agent_id,
      "checked": Array.isArray(_vm.selectedReps) ? _vm._i(_vm.selectedReps, _vm.rep.agent_id) > -1 : _vm.selectedReps
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.selectedReps,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = _vm.rep.agent_id,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.selectedReps = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.selectedReps = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.selectedReps = $$c;
        }
      }, function ($event) {
        return _vm.checkUpline(_vm.rep.agent_id);
      }]
    }
  }), _vm._v(" " + _vm._s(_vm.rep.agent_id) + " " + _vm._s("".concat(_vm.rep.agent_name, " - Level ").concat(_vm.rep.agent_level, " (").concat(_vm.rep.agent_code, ")")) + " "), _vm.rep.downline && _vm.rep.downline.length > 0 ? [_c('rep-toggle', {
    attrs: {
      "uplineId": _vm.rep.agent_id
    }
  })] : _vm._e(), _c('br'), _c('small', {
    staticClass: "email-text"
  }, [_vm._v(_vm._s(_vm.rep.agent_email))])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }