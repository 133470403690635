<template>
  <b-modal
    id="repTreeModal"
    centered
    size="lg"
    no-close-on-backdrop
    hide-footer
    @shown="checkRepTree"
  >
    <template #modal-header>
      <div class="addRep-header text-center">
        <h4>Add Rep</h4>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times" />
        </b-button>
      </div>
    </template>
    <label class="mb-2 d-block"
      >Select Reps
      <span v-if="showSpinner">
        <spinner-grow :styles="spinnerStyles" />
      </span>
      <span v-else class="clr-theme"
        >(
        {{
          `${sendRepMessageSelectedReps.length} ${
            sendRepMessageSelectedReps.length > 0 ? "Rep's" : "Rep"
          } Selected`
        }}
        )</span
      ></label
    >
    <rep-tree
      :downline-list="downlineList"
      :check-rep-tree="checkRep"
      :messageCenterType="messageCenterType"
      @onRepTreeUpdate="handleTreeUpdate"
      class="mb-3"
    />
  </b-modal>
</template>
<script>
import SpinnerGrow from "../ui/SpinnerGrow.vue";
import RepTree from "@/components/RepTree/RepTree.vue";
import { mapGetters } from "vuex";
export default {
  components: { RepTree, SpinnerGrow },
  name: "RepTreeModal",
  props: {
    downlineList: {
      type: Object,
      default: () => {},
    },
    messageCenterType: {
      type: String,
      required: false
    }
  },
  data: () => ({
    allSelectedReps: [],
    checkRep: false,
    showSpinner: false,
    spinnerStyles: {
      width: "4px",
      height: "4px",
      background: "#0cf",
    },
  }),
  computed: {
    ...mapGetters({
      sendRepMessageSelectedReps: "sendRepMessageSelectedReps",
    }),
  },
  methods: {
    checkRepTree() {
      this.checkRep = true;
    },
    closeModal() {
      this.$bvModal.hide("repTreeModal");
    },
    handleTreeUpdate(status) {
      this.showSpinner = status;
    },
  },
};
</script>
