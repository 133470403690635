<template>
  <div class="row">
    <div class="col-12">
      <div id="tree" v-if="Object.keys(downlineList).length">
        <div :class="`branch branch-${downlineList.agent_id}`">
          <div class="entry message-downline-tree">
            <!-- {{sendRepMessageSelectedReps}} -->
            <rep-tree-card
              :rep="downlineList"
              @onCheckAllDownline="onCheckAllDownlineHandler"
              @onRemoveDownlines="removeRelatedDownlines"
              :parentUplineId="downlineList.agent_id"
            />
            <template v-if="downlineList.downline">
              <rep-tree-node
                :downline="downlineList.downline"
                :upline="downlineList"
                :all-selected-reps.sync="allSelectedReps"
                @onUpdateSelectedReps="newSelectedReps"
              />
            </template>
          </div>
        </div>
      </div>
      <p v-else class="text-center">No Record Found</p>
    </div>
    <div
      class="form-group text-center mb-3 mt-3"
      v-if="sendRepMessageSelectedReps.length > 0 && showSelectButton"
    >
      <b-button class="univ-btn" type="submit" @click="selectReps">
        Select
      </b-button>
    </div>
  </div>
</template>
<script>
import RepTreeCard from "./RepTreeCard.vue";
import RepTreeNode from "./RepTreeNode.vue";
import { mapGetters } from "vuex";
export default {
  components: { RepTreeCard, RepTreeNode },
  name: "RepTree",
  props: {
    downlineList: {
      type: Object,
      default: () => {},
    },
    checkRepTree: {
      type: Boolean,
      required: true,
      default: false,
    },
    showSelectButton:{
      type:Boolean,
      default:true,
      required:false
    },
    messageCenterType: {
      type: String,
      required:false
    }
  },
  data: () => ({
    allSelectedReps: [],
  }),

  computed: {
    ...mapGetters({
      sendRepMessageSelectedReps: "sendRepMessageSelectedReps",
    }),
  },
  methods: {
    checkRecursiveDownline(uplineId) {
      let parentDiv = document.getElementById(`upline-${uplineId}`);
      let childrenDiv = document.querySelectorAll(`.upline-${uplineId}`);
      let checked = 0;
      let existingSelectedReps = this.$store.getters.sendRepMessageSelectedReps;
      // let existingSelectedReps = [ 179, 204, 265, 597, 610, 627, 691, 117, 713,1037 ,1306,1523,101302];
      childrenDiv.forEach((item) => {
        if (existingSelectedReps.includes(parseInt(item.value))) {
          document.getElementById(item.id).checked = true;
          checked++;
        }
      });
      if (parentDiv && parentDiv.checked) {
        if (checked == childrenDiv.length) {
          let allDiv = document.getElementById(`allDownline-${uplineId}`);
          if (allDiv) {
            document.getElementById(`allDownline-${uplineId}`).checked = true;
          }
        }
      }
    },
    showRecursiveParentDiv(nodeId) {
      let uplineCheckBox = document.getElementById(`upline-${nodeId}`);
      if(uplineCheckBox){
        uplineCheckBox.checked = true;
      }
      let parentDiv = document.querySelector(`.node-branch-${nodeId}`);
      if (parentDiv) {
        let parentDivClass = parentDiv.closest(".d-none");
        let parentNodeIdThroughClassName = parentDivClass
          ? parentDivClass.className.replace("d-none msg-node msg-node-", "")
          : null;
        if (parentNodeIdThroughClassName) {
          let newParent = parentDivClass.closest(".d-none");
          if (newParent) {
            document.querySelector(
              `#toggle-${parentNodeIdThroughClassName}`
            ).innerHTML = "-";

            parentDivClass.classList.remove("d-none");
          }
          this.showRecursiveParentDiv(nodeId);
        }
      }
    },
    checkRep() {
      let existingSelectedReps = this.$store.getters.sendRepMessageSelectedReps;
      existingSelectedReps.forEach((element) => {
        this.showRecursiveParentDiv(element);
        this.checkRecursiveDownline(element);
      });
    },
    newSelectedReps(newSelectedReps) {
      this.$store.commit(
        "setSendRepMessageSelectedReps",
        new Set([...newSelectedReps])
      );
    },
    removeItems(array, itemToRemove) {
      return array.filter((v) => {
        return !itemToRemove.includes(v);
      });
    },
    checkNode(array, downlines = []) {
      let allSelectedReps = this.$store.getters.sendRepMessageSelectedReps;
      if (array.length > 0) {
        array.forEach((element) => {
          let div = document.getElementById(
            `send-message-node-inner-${element}`
          );
          if (allSelectedReps.includes(element)) {
            if (div) {
              div.classList.remove("d-none");
              document.getElementById(`toggle-${element}`).innerHTML = "-";
            }
            document.getElementById(`upline-${element}`).checked = true;
          } else {
            if (div) {
              div.classList.add("d-none");
              document.getElementById(`toggle-${element}`).innerHTML = "+";
            }
            document.getElementById(`upline-${element}`).checked = false;
          }
        });
      } else {
        if (downlines) {
          downlines.forEach((element) => {
            let div = document.getElementById(
              `send-message-node-inner-${element}`
            );
            if (div) {
              div.classList.add("d-none");
              document.getElementById(`toggle-${element}`).innerHTML = "+";
            }
            // keep tree expanded if all downline checkbox is checked and its upline is unchecked
            const uplineCheckbox = document.getElementById(`upline-${element}`);
            const allDownlineCheckbox = document.getElementById(
              `allDownline-${element}`
            );
            if (
              uplineCheckbox &&
              allDownlineCheckbox &&
              allDownlineCheckbox.checked &&
              !uplineCheckbox.checked
            ) {
              div.classList.remove("d-none");
              document.getElementById(`toggle-${element}`).innerHTML = "-";
            }
            document.getElementById(`upline-${element}`).checked = false;
          });
        }
      }
      this.newSelectedReps(array);
    },
    removeRelatedDownlines(downlines) {
      let allSelectedReps = this.removeItems(
        this.$store.getters.sendRepMessageSelectedReps,
        downlines
      );
      let result = new Set([...allSelectedReps]);
      this.$store.commit("setSendRepMessageSelectedReps", result);
      this.checkNode(result, downlines);
    },
    onCheckAllDownlineHandler(selectedReps) {
      this.allSelectedReps = [
        ...new Set([...selectedReps, ...this.allSelectedReps]),
      ];
      this.checkNode(this.allSelectedReps);
    },
    closeModal() {
      /**
       * reset selected reps
       */
      this.$bvModal.hide("addRep");
      this.$store.commit("setSendRepMessageSelectedReps", []);
      this.$store.commit("setSendRepMessageCheckedRep", false);
      this.$emit("onCloseModal");
    },
    selectReps() {
      this.$store.commit("setSendRepMessageCheckedRep", true);
      this.$bvModal.hide("repTreeModal");
      if (this.messageCenterType == 'compose-message') {
        this.$bvModal.show('more-info-advance')
      }
      if (this.messageCenterType == 'draft-message') {
        this.$bvModal.show('draft-advance-filter')
      }
    },
  },
  created() {
    // this.checkRep();
  },
  watch: {
    checkRepTree: {
      handler: function (canCheck) {
        if (canCheck) {
          //wait for dom updates
          this.$emit('onRepTreeUpdate',true);
          let timeOut = 2000;
          if (this.sendRepMessageSelectedReps.length > 850) {
            timeOut = 3500;
          }
          setTimeout(() => {
            this.checkRep();
            this.$emit('onRepTreeUpdate',false);
          }, timeOut);
        }
      },
      immediate: true,
    },
  },
};
</script>
<style src="@/assets/css/downline-tree.css"></style>
<style>
.message-downline-tree {
  min-height: 250px !important;
  max-height: 400px !important;
  overflow: auto;
}
</style>
<style>
.d-none {
  display: none;
}
.toggle {
  font-weight: bolder;
  font-size: 2rem;
}
#addRep .modal-content {
  border: 0px;
  border-radius: 10px;
}
#addRep .modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0 0px 0;
  position: relative;
}
#addRep .modal-footer {
  display: none;
}
.addRep-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
}
.addRep-header p {
  font-size: 14px;
  color: #2c3f58;
}
.addRep-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.addRep-form label {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}
#addRep .modal-footer .univ-btn {
  max-width: 300px;
}
.mr-3 {
  margin-right: 6px;
}
</style>
